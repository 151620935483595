<template>
  <cks-combined-route @active="refreshActive">
    <cks-page-wrap v-loading="loading" style="padding-bottom: 100px;">
      <cks-edit-form
        @updatePosi="updatePosi"
        :isPosi="true"
        title="首页配置"
        @save="save"
        ref="formRef"
        label-position="top"
        :rules="rules"
        :model="form"
      >
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="轮播图片(1~6张, 建议图片尺寸1920px * 1080px, 不应超过1M大小)" prop="bannerText">
              <!-- <cks-upload :sizeW="1920" :sizeH="1080" v-model="form.bannerText" @updateUrl="updateUrl" :fileUrl="form.bannerText" :limit="6"></cks-upload> -->
              <cks-upload v-model="form.bannerText" @updateUrl="updateUrl" :fileUrl="form.bannerText" :limit="6"></cks-upload>
            </el-form-item>
          </el-col>
          <template v-for="item in 4" :key="item">
            <el-col :span="8">
              <el-form-item :label="`标签${item}内容`" :prop="`tag${item}Text`">
                <el-input v-model="form[`tag${item}Text`]" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="`标签${item}名称`" :prop="`tag${item}Name`">
                <el-input v-model="form[`tag${item}Name`]" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="`标签${item}单位`" :prop="`tag${item}Unit`">
                <el-input v-model="form[`tag${item}Unit`]" ></el-input>
              </el-form-item>
            </el-col>
          </template>
          <el-col :span="24">
            <el-form-item prop="briefText">
              <template #label>
                简介段落1
              </template>
              <el-input type="textarea" :rows="5" v-model="form.briefText" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="briefText2">
              <template #label>
                 简介段落2
              </template>
              <el-input type="textarea" :rows="5" v-model="form.briefText2" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="briefText3">
              <template #label>
                 简介段落3
              </template>
              <el-input type="textarea" :rows="5" v-model="form.briefText3" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </cks-edit-form>
    </cks-page-wrap>
  </cks-combined-route>
</template>

<script>
import {
  toRefs, reactive, onMounted,
} from 'vue';
import {
  showSuccess, getRequireRule,
} from '@/utils';
import apis from '@/request/apis';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  setup() {
    const data = reactive({
      formRef: null,
      temp: true,
      form: {},
      rules: {
        briefText: [getRequireRule('简介')],
        briefText2: [getRequireRule('简介段落2')],
        briefText3: [getRequireRule('简介段落3')],
      },
      loading: false,
      quillEditorRef: null,
    });

    async function loadDetail() {
      data.loading = true;
      try {
        const res = await apis.homePageConfiguration.getDetail();
        if (res) {
          Object.keys(res).forEach(key => {
            if (res[key] !== null) {
              data.form[key] = res[key];
            }
          });
        }
      } finally {
        data.loading = false;
      }
    }

    async function save(done) {
      try { await data.formRef.validate(); } catch (e) { return done(false); }
      try {
        await apis.homePageConfiguration.save({
          ...data.form,
        });
        showSuccess('保存成功');
        done();
      } catch (e) {
        done(e);
      }
    }

    onMounted(() => {
      loadDetail();
    });

    const updatePosi = (val = false) => {
      data.temp = val;
    };

    const updateUrl = (val) => {
      data.form.bannerText = val;
    };

    return {
      updatePosi,
      save,
      ...toRefs(data),
      updateUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  height: 100%;
}
::v-deep .quill-editor {
  height: 400px;
}
::v-deep .ql-container{
  height: 400px;
 }
.posi {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20px;
  z-index: 999;
  background-color: #f5f7fa;
  opacity: 0.5;
  cursor:not-allowed;
}
.el-input {
  --el-input-focus-border: #2F51FF;
}
</style>
